export interface Config {
  name: string;
  globalName: string;
  isAmazonEnabled: boolean;
  refreshTime: number;
  bundleUrl: string;
  bids: any[];
  userIds: any[];
}

export interface JarvisResponse {
  name: string;
  mcm?: string;
  schain?: string;
  customParams?: any;
  config: Config;
  videoConfig: Config;
}

const API_URL = `https://arenacloud.cdn.arkadiumhosted.com/jarvis-api-prod`;

const request = <T>(url: string): Promise<T> => new Promise((resolve, reject) => {
  setTimeout(reject, 2000);
  fetch(url)
    .then((res) => res.json())
    .then(resolve, reject);
});

export class JarvisService {
  public fetched: Promise<JarvisResponse>;
  protected host: string;

  constructor(host: string) {
    this.host = host;
    this.fetched = request<JarvisResponse>(`${API_URL}/v2/domain/get/${this.host}`)
      .catch(() => request(`${API_URL}/v2/domain/get/${this.host}?noc`));
  }

  fetch() {
    return this.fetched;
  }
}
