import { useEffect, useRef } from 'preact/hooks';

import { IStore, State, connect, actions } from '../store/';
import { loadScript } from '../utils/loadScript';

declare global {
  interface Window {
    RmpVast: any;
  }
}

const FluidPlayer = ({ generalState, videoUrl, videoVolume, videoError, videoCompleted, setSize }: IStore) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<any>(null);
  const base = document.querySelector('[src*="video-ads.js"]')?.getAttribute('src')?.replace('video-ads.js', '') || 'https://ams.cdn.arkadiumhosted.com/advertisement/video/stable/';
  const playerPromise = import(/*webpackIgnore: true*/ base + 'rmp-vast.js');

  const muted = !videoVolume;

  useEffect(() => {
    playerRef.current?.setMute(muted);
  }, [muted]);

  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.addEventListener('aderror', videoError);
    videoRef.current.addEventListener('adcomplete', videoCompleted);
    videoRef.current.addEventListener('adskipped', videoCompleted);
    playerPromise.then(({ RmpVast }) => {
      playerRef.current = new RmpVast('wazzupMedia', {}, location.search.includes('rmp_debug=true'));
    });
  }, [videoRef.current]);

  useEffect(() => {
    if (generalState !== State.VIDEO) return;
    if (!videoUrl) return;
    if (!playerRef.current) return;
    playerRef.current.loadAds(videoUrl);
    playerRef.current.setMute(muted);
  }, [ playerRef.current, videoUrl, generalState ])

  const opacity = generalState === State.VIDEO ? 1 : 0;

  return (
    <>
      <div class="rmp-container" id="wazzupMedia" style={{ opacity, width: '100%', height: '100%' }} ref={videoRef}>
        <div class="rmp-content">
          <video
            class="rmp-video"
            src="https://ams.cdn.arkadiumhosted.com/advertisement/video/loader_black.mp4"
            playsInline={true}
          ></video>
        </div>
      </div>
    </>
  );
};

export default connect(['generalState', 'videoUrl', 'videoVolume'], actions)(FluidPlayer);
