import { render } from 'preact';
import { useEffect } from 'preact/hooks';

import { IStore, connect, actions, State } from '../store/';
import Loader from './Loader';

export const CTAScreen: React.FC<IStore> = ({ children, config, generalState, bids, startVideo, requestBids }) => {
  const {
    containerClass,
    buttonClass,
    messageBoxClass,
    buttonTextClass,
    colLeftClass,
    colRightClass,
    messageText,
    buttonText,
    customTagline,
  } = config.CTAScreenConfig || {};

  const videoIsLoading = generalState === State.CTA;
  const display = videoIsLoading ? 'flex' : 'none';
  
  useEffect(() => {
    requestBids();
  }, []);

  useEffect(() => {
    if (bids && config.autoplay && generalState === State.CTA) startVideo();
  }, [ config.autoplay, generalState, bids ]);

  const onClick = () => bids ? startVideo() : null;
  const __html = customTagline || '';

  const content = (
    <>
      <div className={`ark-cta-col-left ${colLeftClass || ""}`}></div>
      <div className={`ark-cta-col-right ${colRightClass || ""}`}>
        <div className={`ark-ad-message ${messageBoxClass || ""}`}>
          {messageText || "your game will begin after the following advertisement"}
        </div>
        <div className="ark-cta-buttons">
          <Loader />
          <button className={`ark-cta-play-button ark-ad-button ${buttonClass || ""}`} data-type="button" type="button">
            <div className={`ark-ad-button-text ${buttonTextClass || ""}`}>
              { buttonText || "Play"}
            </div>
          </button>
        </div>
      </div>
    </>
  );

  const appendCTA = (container: HTMLDivElement | null) => {
    if (!container) return;
    if (container.querySelector('.ark-cta-col-left')) {
      return container.insertAdjacentHTML('beforeend', '<div class="observer-trigger"></div>');
    };
    const fragment = document.createDocumentFragment();
    render(content, fragment);
    (container.children[0] || container).appendChild(fragment);
  };

  const ctaLoadingClass = bids ? 'ark-cta-loaded' : 'ark-cta-loading';

  return (
    <>
      {children}
      <div dangerouslySetInnerHTML={{__html}} ref={appendCTA} onClick={onClick} className={`ark-cta ${ctaLoadingClass} ${containerClass || ""}`} style={{display}}>
      </div>
    </>
  );
};

export default connect(['generalState', 'bids', 'config'], actions)(CTAScreen);
