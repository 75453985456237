export function getEids() {
  let id = (document.cookie.match(/ai_user=(.+?);/) || [])[1];
  if (!id) {
    try {
      id = localStorage.getItem('adsid') || '';
      if (!id) {
        id = btoa(Math.random().toString());
        localStorage.setItem('adsid', id);
      }
    } catch (e) {
      id = 'anon';
    }
  }
  return { source: 'arkadium.com', uids: [{ atype: 1, ext: { stype: 'ppuid' }, id }] };
};
