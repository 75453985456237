import { JarvisResponse } from "../jarvis-service";
import { KeyValues } from "../keyvalues";
import { IBid, IProvider } from "../provider";
import { amazonBids, initApstag } from "./amazon-script";

declare global {
  interface Window {
    apstag: any;
  }
}

export class AmazonProvider implements IProvider {
  public name = 'amazon';

  protected enabled = true;
  protected iu: string;
  protected slotId = 'desktop_web_640x480v';

  constructor(protected kv: KeyValues) {
    this.iu = `/100151972/${window.location.hostname}`;
  }

  async configure(config: JarvisResponse): Promise<void> {
    this.enabled = config.videoConfig.isAmazonEnabled;
    this.iu = `/100151972${config.mcm ? ',' + config.mcm : ''}/${window.location.hostname}`;
    if (this.enabled) initApstag(() => {
      window.apstag.init({
        pubID: "661990cc-08cb-45bb-9ee2-90c2a9034640",
        deals: true,
        adServer: "googletag",
        videoAdServer: "DFP",
      });
    });
  }

  requestBids(params: any): Promise<IBid[]> {
    if (!this.enabled) return Promise.resolve([]);

    const slots = [{ slotID: this.slotId, mediaType: "video" }];

    return new Promise((resolve, reject) => {
      setTimeout(() => resolve([]), 2200);
      window.apstag.fetchBids({ slots, timeout: 2000 }, (bids: any[]) => resolve(bids.map(bid => ({
        provider: this,
        bid: { ...bid, cpm: this.getCpm(bid) },
      }))));
    });
  }

  buildVideoUrl(bid: IBid, noGoogle = false): Promise<string> {
    if (noGoogle) return this.buildSimpleUrl(bid);
    const url = window.location.href;
    const custParams = bid.bid.encodedQsParams || (bid.bid as any).helpers?.encodedQsParams();
    return Promise.resolve(
      "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480" +
      "&iu=" + this.iu +
      "&ppid=" + this.kv.getPPID() +
      "&cust_params=" + this.kv.getUrlParam({ retryOnError: '0' }) + '%26' + custParams +
      "&impl=s" +
      "&gdfp_req=1" +
      "&env=vp" +
      "&output=vast" +
      "&unviewed_position_start=1" +
      "&vad_type=linear" +
      "&url=" + url +
      "&description_url=" + url +
      "&correlator=" + parseInt(Math.random() * 1e15 + '')
    );
  }

  protected async buildSimpleUrl(bid: IBid) {
    const { targeting, amzniid, amznbid } = bid.bid;
    return `https://aax.amazon-adsystem.com/e/dtb/vast?b=${amzniid || targeting.amzniid}&rnd=${Math.random()}&pp=${amznbid || targeting.amznbid}`;
  }

  protected getCpm(bid: any) {
    return amazonBids[(bid.amznbid || bid.targeting?.amznbid)?.substr(2)];
  }
}
