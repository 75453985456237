// @ts-nocheck

export const amazonBids = require('../amazon-bids.json');

export function initApstag(cb) {
  if (window.apstag) return;
  function q(c: any, r: any) { window.apstag._Q.push([c, r]); }
  window.apstag = {
    init()           { q('i', arguments); },
    fetchBids()      { q('f', arguments); },
    setDisplayBids() { },
    targetingKeys()  { return []; },
    _Q: [],
  };
  const s = document.createElement('script');
  s.async = true;
  s.src = 'https://c.amazon-adsystem.com/aax2/apstag.js';
  (document.head || document.body).appendChild(s);
  cb();
}

