import { useEffect, useRef } from 'preact/hooks';

import { IStore, State, connect, actions } from '../store/';
import { loadScript } from '../utils/loadScript';

const imaUrl = `https://imasdk.googleapis.com/js/sdkloader/ima3${location.href.includes('ark_ima_debug=true') ? '_debug' : '' }.js`;
const imaLoaded = loadScript(imaUrl);

const log = (...args: any[]) => console.log('>>>', ...args);

const VastIMAPlayer = ({ generalState, videoUrl, videoVolume, videoError, imaFailed, videoCompleted }: IStore) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const displayAdContainer = useRef<HTMLDivElement | null>(null);
  const adsManager = useRef<any | null>(null);
  const muted = !videoVolume;
  let adDisplayContainer: any;
  let adsLoader: any;

  useEffect(() => {
    if (!adsManager.current) return;
    adsManager.current.setVolume(videoVolume);
  }, [adsManager.current, videoVolume]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (!displayAdContainer.current) return;
    if (!videoUrl) return;
    imaLoaded.catch(() => imaFailed());
    imaLoaded.then(() => {
      const { ima } = window.google;

      if (!adDisplayContainer) {
        adDisplayContainer = new ima.AdDisplayContainer(displayAdContainer.current, videoRef.current);
        adDisplayContainer.initialize();
      }

      if (!adsLoader) {
        adsLoader = new ima.AdsLoader(adDisplayContainer);
      }

      const adsRequest = new ima.AdsRequest();

      ima.settings.setVpaidMode(ima.ImaSdkSettings.VpaidMode.INSECURE);
      adsRequest.setAdWillAutoPlay(false);
      adsRequest.setAdWillPlayMuted(muted);
      adsRequest.contentTitle = document.title;
      adsRequest.pageUrl = location.href;
      adsRequest.forceNonLinearFullSlot = true;
      adsRequest.adTagUrl = videoUrl;
      adsRequest.adTagUrl = videoUrl;
      adsRequest.linearAdSlotWidth = videoRef.current?.clientWidth;
      adsRequest.linearAdSlotHeight = videoRef.current?.clientHeight;
      adsRequest.nonLinearAdSlotWidth = videoRef.current?.clientWidth;
      adsRequest.nonLinearAdSlotHeight = videoRef.current?.clientHeight;

      const onLoaded = (event: any) => {
        const { clientWidth, clientHeight } = videoRef.current!;
        log('Ads manager init');
        adsManager.current = event.getAdsManager(videoRef.current);

        adsManager.current.addEventListener(ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, () => {
          displayAdContainer.current!.innerHTML = '';
          adsManager.current.destroy();
          adsManager.current = null;
          videoCompleted();
        });

        videoRef.current?.load();
        adDisplayContainer.initialize();
        try {
          adsManager.current.init(clientWidth, clientHeight, ima.ViewMode.NORMAL);
          adsManager.current.start();
        } catch (err) {
          console.error(err);
          videoRef.current!.play();
        }
      };

      const onError = (err: any) => {
        if (adsManager.current) adsManager.current.destroy();
        videoError(err);
      };

      adsLoader.addEventListener(ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, onLoaded, false);
      adsLoader.addEventListener(ima.AdErrorEvent.Type.AD_ERROR, onError, false);

      adsLoader.requestAds(adsRequest);
    });
  }, [ videoRef.current, displayAdContainer.current, videoUrl ]);


  const opacity = generalState === State.VIDEO ? 1 : 0;

  return (
    <>
      <div className="vast-media">
        <video ref={videoRef}
          style={{ width: '100%', opacity, transition: 'opacity 1s ease-in', backgroundColor: 'black' }}
          controls={false}
          playsInline={true}
          src="https://ams.cdn.arkadiumhosted.com/advertisement/video/loader_black.mp4"
          muted={muted ? true : false}
          >
            Sorry, your browser doesn't support embedded videos.
        </video>
        <div className="display-ad-container" ref={displayAdContainer} style={{ width: '100%', position: 'absolute', left: 0, top: 0 }}></div>
      </div>
    </>
  );
};

export default connect(['generalState', 'videoUrl', 'videoVolume'], actions)(VastIMAPlayer);
