import { WeSellService } from './WeSellService';
import { MsnAdsService } from './MsnService';
import { MashableAdsService } from './MashableAdsService';
import { ElPaisAdsService } from './ElPaisService';

declare var THEY_SELL: undefined
  | 'games.msn.com'
  | 'juegos.elpais.com'
  | 'games.mashable.com';

export const AdsService = 
  THEY_SELL === 'games.msn.com' ? MsnAdsService :
  THEY_SELL === 'games.mashable.com' ? MashableAdsService :
  THEY_SELL === 'juegos.elpais.com' ? ElPaisAdsService :
  WeSellService;

