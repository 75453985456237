import { FunctionComponent } from 'preact';

import { useEffect, useState, useRef } from "preact/hooks";
import { connect, IStore, actions } from "../store/index";

declare module 'preact/src/jsx' {
  namespace JSXInternal {
    interface IntrinsicElements {
      "display-ad-component": any;
    }
  }
}

const allSizes = [
  [970, 250],
  [970, 90],
  [728, 90],
  [336, 280],
  [320, 100],
  [320, 50],
  [300, 600],
  [300, 250],
  [250, 250],
  [160, 600],
];

const DisplayAd = ({
  maxSize,
  onRender,
}: {
  maxSize: number[];
  onRender: () => void;
}) => (
  <>
    <display-ad-component
      data-id="ark_video_fallback"
      dimensions={JSON.stringify(
        allSizes.filter((s) => s[0] < maxSize[0] && s[1] < maxSize[1])
      )}
      onLoaded={onRender}
    ></display-ad-component>
  </>
);

export const DisplayFallback = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [wh, setWH] = useState<any>(null);

  useEffect(() => {
    if (divRef.current) {
      setWH([divRef.current.clientWidth, divRef.current.clientHeight]);
    }
  }, [divRef.current]);

  return (
    <div ref={divRef} className="ark-ad-display-container">
      {/* @ts-ignore */}
      {wh && <DisplayAd maxSize={wh} onRender={() => null} />}
    </div>
  );
};

export default connect(['generalTimeLeft'], actions)(DisplayFallback);
