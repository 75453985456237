import createStore, { ActionMap, Store } from 'unistore';

import { connect as originalConnect, Provider as originalProvider } from 'unistore/preact';
import adPodsEnabled from '../rules/adpods';
import { IBid } from '../services/provider';
import { IAdsService } from '../types/IAdsService';
import { IConfig } from '../types/IConfig';

import debugFactory from '../utils/debug';

export const connect: any = originalConnect as any;
export const Provider: any = originalProvider;

const dlog = debugFactory('arkadium-ads:video:PrerollStore');

export enum State {
  CTA,
  VIDEO,
  FALLBACK,
  FINISH,
}

export interface IState {
  config: IConfig;
  service?: IAdsService;
  generalState: State;
  generalDuration: number;
  generalTimeLeft: number;
  generalInterval?: ReturnType<typeof setInterval>;
  progressTimeout?: ReturnType<typeof setTimeout>;
  videoUrl: string;
  videoVolume: number;
  videoAdCount: number;
  videoRetryFired: boolean;
  videoRetryCount: number;
  videoSize: [number, number];
  videoIsLoading: boolean;
  videoIMAFailed?: boolean;
  videoBid?: { bidder: string, cpm: number, url: string };
  bids?: IBid[];
};

type DropFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;
type ActionsReturn<T extends { [key: string]: (...p: any[]) => any }> = {
  [K in keyof T]: (...p: DropFirst<Parameters<T[K]>>) => ReturnType<T[K]>;
}
export type IStore = IState & ActionsReturn<ReturnType<typeof actions>>;

const initialState: IState = {
  generalState: State.CTA,
  generalDuration: 30,
  generalTimeLeft: 0,
  videoUrl: '',
  videoVolume: 1,
  videoAdCount: 1,
  videoSize: [640, 480],
  videoIsLoading: true,
  videoRetryFired: false,
  videoRetryCount: 0,
  config: {} as any,
};

export const newStore = () => createStore(initialState);

export const setConfig = (_: IState, config: IConfig, service: IAdsService) => {
  if (!config.finalCallback) {
    config.finalCallback = window.videoAdConfigs?.finalCallback || (() => {
      const endPrerollEvent = new Event("onPrerollEnd");
      const prerollElem = document.getElementById("ark_pre-roll");
      if (prerollElem) prerollElem.dispatchEvent(endPrerollEvent);
    });
  }
  if (!config.onCTAStateChange) {
    config.onCTAStateChange = (({ isShowing }: any) => {
      console.warn('CTAStateChange: ' + isShowing);
    });
  }
  return { config, service };
};

declare global {
  interface Window {
    ReportingObserver: any;
  }
}

export const actions: (s: Store<IState>) => ActionMap<IState> = function(store) {
  return {
    setSize(_: IState, w: number, h: number) {
      return { videoSize: [w, h] };
    },

    requestBids(state: IState) {
      const { config, service } = state;
      if (!service) return { generalState: State.FALLBACK };
      const generalTimeLeft = config.duration || 30;
      return service.request(config)
        .then((bids) => ({ bids, generalTimeLeft, generalDuration: generalTimeLeft }));
    },

    startVideo(state: IState) {
      const generalInterval = setInterval(() => store.setState({ generalTimeLeft: store.getState().generalTimeLeft - 1 }), 1000);
      const fallback = { generalState: State.FALLBACK, generalInterval, generalTimeLeft: 10, generalDuration: 10 };

      const { bids, config, videoIMAFailed } = state;
      if (!bids) return fallback;

      const bid = bids.shift();
      if (!bid) return fallback;

      config.container.classList.add('playing');
      config.onCTAStateChange({ isShowing: false });

      return bid.provider.buildVideoUrl(bid, videoIMAFailed)
        .then((videoUrl) => ({ videoUrl, generalState: State.VIDEO, generalInterval }));
    },

    videoCompleted(state) {
      dlog('VideoCompleted');
      const { generalTimeLeft, service, config, videoAdCount } = state;
      const useAdPods = service &&
        !config.isDev &&
        (generalTimeLeft > 10) &&
        adPodsEnabled() &&
        videoAdCount < 4;

      if (useAdPods) {
        return service.request({ ...config, duration: generalTimeLeft }).then(async (bids) => {
          store.setState({ bids });
          const bid = bids.shift();
          if (!bid) return { generalState: State.FALLBACK };
          const videoUrl = await bid.provider.buildVideoUrl(bid);
          return { videoUrl, videoAdCount: videoAdCount + 1 };
        });
      }

      return { generalTimeLeft: 1 };
    },

    imaFailed(state: IState) {
      return { videoIMAFailed: true };
    },

    videoError(state: IState, e: any, details: any) {
      const { videoRetryCount, service, config, bids, generalState, generalTimeLeft, videoIMAFailed } = state;

      dlog('VideoError', e);
      if (generalState !== State.VIDEO) return;

      const fallback = { generalState: State.FALLBACK, generalTimeLeft: 10, generalDuration: 10 };
      if (!bids) return fallback;

      const bid = bids.shift();
      if (!bid) return fallback;

      return bid.provider.buildVideoUrl(bid, videoIMAFailed)
        .then((videoUrl) => ({ videoUrl, videoRetryCount: videoRetryCount + 1 }));
    },

    finish(state: IState) {
      const { generalInterval, config } = state;

      if (config.finished) return;

      clearInterval(generalInterval);
      config.finished = true;
      config.finalCallback();
      dlog('Finish!');
      return { generalState: State.FINISH };
    },

    setVolume(_: IState, videoVolume: number) {
      return { videoVolume };
    },

    skipClicked() {
      return { generalTimeLeft: 0 };
    },
  }
};

