import { render } from "preact";

import { IConfig } from "./types/IConfig";
import { Provider, newStore, setConfig } from './store/';
import { AdsService } from './services/ads/';

import App from "./App";

import renderAllPixels from "./pixels";

import "./styles.css";

const SHIM_EXCLUDE_DOMAINS = [
  'arcade.hsn.com',
  'www.arkadium.com',
  'www-dev.arkadium.com',
  'www-dev2.arkadium.com',
  'www-dev3.arkadium.com',
  'www-dev4.arkadium.com',
];

class AdVideo {
  static coreService = new AdsService();

  render(config: IConfig, retryCount = 0): any {
    const container = document.getElementById(config.element || 'ark_pre-roll');

    if (!container) {
      if (retryCount < 10) {
        return setTimeout(() => this.render(config, retryCount + 1), 500);
      } else {
        throw new Error(`Can't find container "#${config.element}"`);
      }
    }

    renderAllPixels(container);
    const cfg = { ...config, container };
    container.classList.add('ark-preroll-container');
    const videoEl = document.createElement('div');
    if (['arena', 'xml'].includes(config.product || '') && !SHIM_EXCLUDE_DOMAINS.includes(location.hostname)) {
      if (!container.parentElement) {
        return setTimeout(() => this.render(config, retryCount + 1), 1000);
      }
      container.parentElement.appendChild(videoEl);
      videoEl.appendChild(container);
    }

    const store = newStore();
    store.action(setConfig)(cfg, AdVideo.coreService);
    return render(<Provider store={ store }><App /></Provider>, container);
  }

  static preload() {
    console.warn("AdVideo#preload shouldn't be used anymore");
  }
}

const w = window as any;
w.__ark_ads__ = w.__ark_ads__ || {};
w.__ark_ads__.AdVideo = w.__ark_ads__.AdVideo || AdVideo;

