import TypePreroll from "./TypePreroll";
import {connect} from "./store";

declare global {
  interface Window {
    google: any;
    videoAdConfigs: any;
  }
}

interface IAppProps {
  config: any;
}

export const App: React.FC<IAppProps> = ({ config }) => {
  return <TypePreroll config={config} />
};

export default connect('config')(App);

