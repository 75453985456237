function supportsVideoMime(mime: string) {
  const elem = document.createElement('video');
  if (typeof elem.canPlayType !== 'function') return false;

  return ['maybe', 'probably'].includes(elem.canPlayType(mime));
}

const mimes = ['video/ogg', 'video/webm', 'video/x-m4v', 'video/quicktime', 'video/wmv'];
const supportedMimes = mimes.filter(supportsVideoMime);

export function getSupportedMimes() {
  return supportedMimes;
};
